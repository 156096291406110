<template>
  <div class="map-box" :style="{width}">
    <el-form class="form-inline" :inline="true">
      <el-form-item>
        <el-autocomplete
          v-model="search"
          :fetch-suggestions="querySearch"
          value-key="name"
          :trigger-on-focus="false"
          style="width: 280px;margin-right: 20px;"
        ></el-autocomplete>
        <el-button type="primary" size="small " @click="getLatLngBounds">查询</el-button>
      </el-form-item>
      <el-form-item label="当前坐标">
        <el-input :value="`${latitude},${longitude}`" size="small" style="width: 200px;" readonly></el-input>
      </el-form-item>
      <el-form-item label="当前地址">
        <el-input v-model="shopAddress" size="small" style="width: 565px" readonly></el-input>
      </el-form-item>
    </el-form>

    <div id="map" :style="{width, height}"></div>

  </div>
</template>

<script>
export default {
  props: {
    //地图key
    mapKey: {
      type: String,
      default: 'YJEBZ-Z536R-4TPWU-WB7T4-75NBF-T3F6K'
    },
    //设置地图宽度
    width: {
      type: String,
      default: '100%'
    },
    //设置地图高度
    height: {
      type: String,
      default: '500px'
    },
    //周边位置高度
    boundsHeight: {
      type: String,
      default: '200px'
    },
    address: {
      type: String,
      default: ''
    },
    lat: {
      type: [String, Number],
      default: 0
    },
    lng: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      latLng: [], //默认位置，之后会自动定位到当前位置。
      search: "", //搜索关键字
      marker: null, //标记点
      map: null, //地图实例
      //获取周边区域定时器
      setTime: null,
      selectPosition: -1,  //选择的位置
      BoundsPois: [], //周边列表
      shopAddress: '',
      longitude: '',
      latitude: '',
      markersArray: []
    };
  },
  watch: {
    address(e) {
      this.shopAddress = e
    },
    lat(e) {
      this.latitude = e
      if (!e) {
        this.getLocation()
      } else {
        this.map.setCenter(new qq.maps.LatLng(this.lat, this.lng))
      }
    },
    lng(e) {
      this.longitude = e
    },
  },

  created() {
    this.loadScript();
  },

  methods: {
    //异步加载地图js
    loadScript() {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.charset = "utf-8";
      //地图js加载完成的回调，初始化地图
      window.initMap = () => {
        this.init();
      };
      script.src =
        "https://map.qq.com/api/js?v=2.exp&key=" +
        this.mapKey +
        "&callback=initMap";
      document.body.appendChild(script);
    },

    //初始化地图
    init() {
      this.map = new qq.maps.Map(document.getElementById("map"), {
        center: new qq.maps.LatLng(this.lat, this.lng), //设置地图中心的
        keyboardShortcuts: false,  //禁止键盘操控
        disableDefaultUI: false,    //禁止所有控件
        zoom: 16
      });
      //绑定地图点击事件
      qq.maps.event.addListener(this.map, "click", e => {
        this.getLatLng(e);
        this.getAddress(e.latLng)
      });
      //绑定地图缩放级别更改事件
      qq.maps.event.addListener(this.map, "zoom_changed", e => {
        //重置选择、搜索框
        this.search = '';
        this.selectPosition = -1;
      });
    },

    querySearch(e, cb) {
      let latlngBounds = new qq.maps.LatLngBounds();
      //调用Poi检索类
      let searchService = new qq.maps.SearchService({
        complete: results => {
          cb(results.detail.pois)
        }
      });
      //周边位置个数10个
      searchService.setPageCapacity(10);
      searchService.searchInBounds(this.search, latlngBounds); //根据范围和关键字进行指定区域检索。
    },
    //点击地图，获取定位度
    getLatLng(e) {
      this.$emit("getLatLng", [e.latLng.lat, e.latLng.lng]);
    },

    //搜索位置，查询周边位置信息
    getLatLngBounds(init) {
      this.selectPosition = -1;
      let latlngBounds = new qq.maps.LatLngBounds();
      //调用Poi检索类
      let searchService = new qq.maps.SearchService({
        complete: results => {
          if (this.markersArray.length) {
            for (var i in this.markersArray) {
              this.markersArray[i].setMap(null)
            }
            this.markersArray.length = 0
          }
          results.detail.pois.forEach(item => {
            this.setMarker(item.latLng)
          })
          this.map.setCenter(results.detail.pois[0].latLng);
        }
      });
      //周边位置个数10个
      searchService.setPageCapacity(10);
      searchService.searchInBounds(this.search, latlngBounds); //根据范围和关键字进行指定区域检索。
    },

    //根据经纬度进行位置解析
    getAddress(latLng) {
      let geocoder = new qq.maps.Geocoder({
        complete: result => {
          console.log('根据经纬度进行位置解析', result)
          const address = result.detail.addressComponents
          // this.shopAddress = address.province + address.city + address.district + address.street
          this.shopAddress = result.detail.nearPois[0].address
          this.latitude =  result.detail.location.lat
          this.longitude =  result.detail.location.lng
          //添加当前的经纬度信息
          let nowPostin = {name: '当前标注位置', address: result.detail.address, latLng: {lat: result.detail.location.lat, lng: result.detail.location.lng}};
          this.$emit('change', {
            address: result.detail.nearPois[0].address,
            latitude: result.detail.location.lat,
            longitude: result.detail.location.lng
          })
        }
      });
      geocoder.getAddress(latLng || this.map.getCenter());
    },

    //获取当前位置
    getLocation() {
      let cs = new qq.maps.CityService({
        map: this.map,
        complete: results => {
          this.latLng = [results.detail.latLng.lat, results.detail.latLng.lng];
          //设置地图中心位置为当前定位。
          this.map.setCenter(results.detail.latLng);
          //获取当前位置的周边范围
          this.getAddress();
        }
      });
      //调用searchLocalCity()方法获取位置
      cs.searchLocalCity();
    },

    //设置底部标记点
    setMarker(center) {
      var marker = new qq.maps.Marker({
        map: this.map,
        position: center
      });
      qq.maps.event.addListener(marker, 'click', e => {
       this.getAddress(e.latLng)
      })
      
      this.markersArray.push(marker)
    }
  }
};
</script>

<style lang="less" scoped>
.map-box {
  margin: 20px 0 0;
}
.map-search {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  .search {
    width: 200px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    color: #606266;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    background-color: #fff;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    outline: none;
    &::-webkit-input-placeholder {
      color: #c0c4cc;
    }
  }
  .button {
    cursor: pointer;
    line-height: 1;
    border: 1px solid #409eff;
    text-align: center;
    outline: none;
    margin: 0 10px;
    transition: 0.1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    background-color: #409eff;
    &:hover:active {
      opacity: 1;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
.bounds{
  height: 200px;
  border-top: 1px solid #d3d3d3;
  background-color: #fff;
  overflow-y: auto;
  .list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #d3d3d3;
    cursor: pointer;
    .name{
      margin: 0;
      font-size: 14px;
    }
    .address{
      font-size: 12px;
      color: gray;
    }
    .yes{
      position: relative;
      display: inline-block;
      &.on{
        &::after{
          background-color: #409eff;
        }
        &::before{
          background-color: #409eff;
        }
      }
      &::after{
        content: "";
        position: absolute;
        right: 10px;
        width: 20px;
        height: 2px;
        border-radius: 20px;
        transform: rotate(-45deg);
      }
      &::before{
        content: "";
        position: absolute;
        right: 25px;
        top: 3px;
        width: 10px;
        height: 2px;
        border-radius: 20px;
        transform: rotate(45deg);
      }
    }
  }
}
.el-form-item {
  width: initial;
}
.form-inline {
  margin-bottom: 10px;
}
</style>