<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>商家管理</el-breadcrumb-item>
            <el-breadcrumb-item>添加/编辑</el-breadcrumb-item>

          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="序号" :error="$v.form.sorder.$error ? '请输入序号' : ''">
          <el-input v-model.number="form.sorder" type="number"></el-input>
        </el-form-item>

        <el-form-item label="商家名称" :error="$v.form.shopname.$error ? '请输入商家名称' : ''">
          <el-input v-model="form.shopname"></el-input>
        </el-form-item>
        <el-form-item label="联系人" :error="$v.form.name.$error ? '请输入联系人' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="联系人电话" :error="$v.form.phone.$error ? '请输入联系人电话' : ''">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>

        <!-- <el-form-item
          label="地址经度"
          :error="$v.form.shoplng.$error ? '请输入地址经度' : ''"
        >
          <el-input v-model="form.shoplng"></el-input>
        </el-form-item>
                <el-form-item
          label="地址纬度"
          :error="$v.form.shoplat.$error ? '请输入地址纬度' : ''"
        >
          <el-input v-model="form.shoplat"></el-input>
        </el-form-item> -->

        <el-form-item label="商家地址" :error="$v.form.shopaddress.$error ? '请输入商家地址' : ''">
          <el-input v-model="form.shopaddress"></el-input>
        </el-form-item>

        <el-form-item label="LOGO" :error="$v.form.shoplogo.$error ? '请上传LOGO' : ''">
          <image-upload @upload="imageUpload" :image="form.shoplogo" />
        </el-form-item>

        <el-form-item label="状态">
          <el-radio-group v-model="form.states">
            <el-radio :label=1>启用</el-radio>
            <el-radio :label=0>禁用</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Tinymce from '@/components/tinymce'
import ImageUpload from '@/components/imageUpload'
import FileUpload from '@/components/fileUpload'
import { editRequest, getdatilRequest } from '@/api/zrshop'

import { required } from 'vuelidate/lib/validators'
import utils from '@/utils/util'
import getMapLatLng from '@/components/map'
export default {
  name: 'Articletypeedit',
  data() {
    return {
      form: {
        id: 0,
      },

      typeId: 0,
    }
  },
  components: {
    'image-upload': ImageUpload,
    'file-upload': FileUpload,
    tinymce: Tinymce,
    getMapLatLng,
  },
  created() {
    this.form.id = this.$route.query['id']

    this.getDetail()
  },
  methods: {
    // 地图选择
    changeMap(e) {
      console.log('地图选择', e)
      this.form.shopaddress = e.address
      this.form.shoplng = e.longitude
      this.form.shoplat = e.latitude
    },
    back() {
      this.$router.back()
    },
    imageUpload(url) {
      this.form.shoplogo = url
    },

    getDetail() {
      getdatilRequest({
        Id: this.form.id,
      }).then((res) => {
        if (this.form.id == 0) {
          res.data.productid = ''
          res.data.shopid = ''
        }
        this.form = res.data
      })
    },

    save() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form))
        params.shoplogo = utils.removeUrl(params.shoplogo)
        editRequest(params).then((res) => {
          if (res.code == 1) {
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  },
  validations: {
    form: {
      sorder: {
        required,
      },
      shopname: {
        required,
      },
      shoplogo: {
        required,
      },

      name: {
        required,
      },
      phone: {
        required,
      },
      states: {
        required,
      },

      shopaddress: { required },
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
